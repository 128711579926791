import React, { useState, useEffect } from "react";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
  Grid,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Checkbox,
  CheckboxProps,
  CircularProgress,
  Collapse,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import {
  OutlindedFieldContainer,
  StyledButton,
  FormText,
  FileContainer,
  DeleteIcon,
  StyledFormGroup,
  StyledTextField,
  StyledAlert,
} from "./styles";
import { FormTitle } from "../FormTitle";
import PageContainer from "../PageContainer";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import { useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { colors } from "../../styles/variables";
import { getFiles } from "../toBase64File";
import { callEmailAPI } from "../emailSend";
import BannerImage from "../BannerImg";
import banner from "../../images/024_Gippsland.jpg";

const GreenCheckbox = withStyles({
  root: {
    color: "#828282",
    "&$checked": {
      color: "#158da7",
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: `${theme.spacing(1)} 0`,
        width: "100%",
      },
    },
    top: {
      color: `${colors.dentBlue}`,
      animationDuration: "550ms",
      width: "1rem !important",
      height: "1rem !important",
      margin: "0 0.5rem 0 0",
    },
  })
);
const NewPatient = () => {
  const [isUploadFiles, setIsUploadFiles] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [openSucceed, setOpenSucceed] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openFileSize, setOpenFileSize] = useState(false);
  const [uploadFile, setUploadFile] = useState([]);
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyles();

  const mobileProps = {
    maxLength: 10,
  };
  const nameProps = {
    maxLength: 40,
  };

  const onSubmit = async (data) => {
    setSubmitting(true);

    data.requestPage = "New Patient Booking";
    let files;
    await getFiles(uploadFile).then((customJsonFile) => {
      files = customJsonFile;
    });
    data.files = files;

    // let formData = new FormData();
    // formData.append('fileName', uploadFile[0].path)
    // formData.append('files', uploadFile[0])
    // formData.append('firstName', data.firstName)
    // formData.append('lastName', data.lastName)
    // formData.append('email', data.email)
    // formData.append('mobile', data.mobile)
    // formData.append('isAppointment', data.isAppointment)
    // formData.append('isUploadFiles', data.isUploadFiles)
    // formData.append('requestPage', data.requestPage)

    const maxFileSize = 4194304;
    let totalFileSize = 0;
    files.map((item) => (totalFileSize = totalFileSize + item.fileSize));

    if (totalFileSize < maxFileSize) {
      await callEmailAPI(data).then(({ isSubmitting, response }) => {
        setSubmitting(isSubmitting);
        response.ok ? setOpenSucceed(true) : setOpenError(true);
      });
    } else {
      setOpenFileSize(true);
      setSubmitting(false);
      setUploadFile([]);
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone();

  const deleteFile = (name) => {
    const index = uploadFile.findIndex((file) => file.name === name);

    uploadFile.splice(index, 1);
    setUploadFile([...uploadFile]);
  };

  const changingUploadFilesCheck = () => {
    setIsUploadFiles(!isUploadFiles);

    if (isUploadFiles) {
      setUploadFile([]);
    }
  };

  useEffect(() => {
    setUploadFile([...uploadFile, ...acceptedFiles]);
  }, [acceptedFiles]);
  return (
    <>
      <Breadcrumb pageTitle="Start the process">
        <BreadcrumbItem Link="/new-patient" Text="Start the process" />
      </Breadcrumb>

      <BannerImage imagesrc={banner}></BannerImage>

      <PageContainer>
        <FormTitle>New Patient Registration</FormTitle>
        <OutlindedFieldContainer>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="First name"
                  variant="outlined"
                  type="text"
                  inputRef={register({ required: true, maxLength: 40 })}
                  inputProps={nameProps}
                  name="firstName"
                  error={errors.firstName ? true : false}
                />
                <span className="error-message">
                  {errors.firstName && "First name is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Last name"
                  variant="outlined"
                  type="text"
                  inputRef={register({ required: true, maxLength: 40 })}
                  inputProps={nameProps}
                  name="lastName"
                  error={errors.lastName ? true : false}
                />
                <span className="error-message">
                  {errors.lastName && "Last name is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Mobile"
                  variant="outlined"
                  type="text"
                  inputRef={register({
                    required: true,
                    minLength: 10,
                    maxLength: 10,
                    pattern: /[0][4][0-9]{8}/,
                  })}
                  inputProps={mobileProps}
                  name="mobile"
                  error={errors.mobile ? true : false}
                />
                <span className="error-message">
                  {errors.mobile && "10 digits mobile number is required"}
                </span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Email"
                  variant="outlined"
                  type="text"
                  inputRef={register({
                    required: true,
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                  name="email"
                  error={errors.email ? true : false}
                />
                <span className="error-message">
                  {errors.email && "Email address is required"}
                </span>
              </Grid>
            </Grid>

            <StyledFormGroup row>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    inputRef={register({ required: false })}
                    name="isAppointment"
                  />
                }
                label="Request appointment"
              />
              {/* <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={isUploadFiles}
                    onChange={() => changingUploadFilesCheck()}
                    inputRef={register({ required: false })}
                    name="isUploadFiles"
                  />
                }
                label="Upload files"
              /> */}
            </StyledFormGroup>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div hidden={!isUploadFiles}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FileContainer
                        {...getRootProps({
                          isDragActive,
                          isDragAccept,
                          isDragReject,
                        })}
                      >
                        <FormText>Drop files here or click to upload.</FormText>
                        <input {...getInputProps()} />
                      </FileContainer>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <List dense={false}>
                        {uploadFile.map((file) => (
                          <ListItem key={file.path}>
                            <ListItemText primary={file.name} />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => deleteFile(file.name)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <Collapse in={openSucceed}>
              <StyledAlert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenSucceed(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Email sent successfully
              </StyledAlert>
            </Collapse>
            <Collapse in={openError}>
              <StyledAlert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Error occurred. Please contact us.
              </StyledAlert>
            </Collapse>
            <Collapse in={openFileSize}>
              <StyledAlert
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenFileSize(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Total file size should be less than 4MB. Please send files
                through an email.
              </StyledAlert>
            </Collapse>
            <Grid container spacing={3} justify="center">
              <Grid item xs={12} sm={6}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                >
                  {submitting ? (
                    <CircularProgress className={classes.top} />
                  ) : null}
                  Send
                </StyledButton>
              </Grid>
            </Grid>
          </form>
        </OutlindedFieldContainer>
      </PageContainer>
    </>
  );
};

export default NewPatient;
