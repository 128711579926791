import styled, { css } from "styled-components";
import { breakpoints, colors } from "../../styles/variables";
import { Button, FormGroup, TextField } from "@material-ui/core";
import { RiDeleteBin5Line } from "react-icons/ri";
import Alert from "@material-ui/lab/Alert";

export const OutlindedFieldContainer = styled.div`
  position: relative;
  .MuiOutlinedInput-notchedOutline {
    top: 0px;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #158da7;
    border-width: 2px;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    background-color: #fff;
    padding: 0 0.375rem;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #158da7;
  }
  .error-message {
    display: block;
    color: #f44336;
    padding-left: 0.875rem;
    margin-top: 3px;
    font-size: 0.75rem;
  }
`;
export const StyledButton = styled(Button)`
  background-color: #158da7;
  padding: 1rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  &:hover {
    background-color: #0bb4ac;
  }
`;

export const FormText = styled.p`
  font-size: 16px;
  margin: 0;
  padding: 2rem 0.875rem;
  color: ${colors.dentGray};
`;

export const FileContainer = styled.div`
  border: 2px dashed rgba(0, 0, 0, 0.23);
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  margin: 0.5rem 0;
  background: ${colors.lightGray};
`;
const IconStyle = css`
  color: #333;
  &:hover {
    color: ${colors.primary};
  }
`;
export const DeleteIcon = styled(RiDeleteBin5Line)`
  ${IconStyle}
`;

export const StyledFormGroup = styled(FormGroup)`
  flex-direction: column;
  @media (min-width: ${breakpoints.sm}) {
    flex-direction: row;
  }
`;
export const StyledTextField = styled(TextField)`
  width: 100%;
`;
export const StyledAlert = styled(Alert)`
  position: absolute;
  bottom: -3rem;
  right: 0;
`;
