import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import NewPatient from "../components/new-patient";

const NewPatientPage = () => (
  <Layout>
    <SEO title="Dental Service for New Patient" />
    <NewPatient />
  </Layout>
);

export default NewPatientPage;
